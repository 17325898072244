<template>
  <div>
    <a
      @click="$router.push({ name: 'staff-transactions' })"
      
    >
      <feather-icon icon="ArrowLeftIcon" class="cursor-pointer align-bottom"/>Back to list
    </a>
    <vx-card class="mt-5">
      <h4 class="font-bold">Payment request details</h4>
      <vs-row>
        <vs-col vs-w="4" vs-xs="12">
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer name</label>
            <span class="font-bold">{{ paymentRequestDetail.payeeName }}</span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer mobile</label>
            <span class="font-bold">{{ paymentRequestDetail.payeePhone | phoneNumber}}</span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer email</label>
            <span class="font-bold"><a :href="`mailto:${paymentRequestDetail.payeeEmail}`">{{ paymentRequestDetail.payeeEmail }}</a></span>
          </div>
        </vs-col>
        <vs-col vs-w="4" vs-xs="12">
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Your reference</label>
            <span class="font-bold">
              {{ paymentRequestDetail.reference }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Amount</label>
            <span class="font-bold">
              {{ moneyFormat(paymentRequestDetail.amount) }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Request type</label>
            <span class="font-bold">
              {{
                paymentRequestDetail.requestType
                  ? paymentRequestDetail.requestType
                  : "one-off" | capitalize
              }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Account</label>
            <span class="font-bold">
              {{
                paymentRequestDetail.bank
                  ? paymentRequestDetail.bank.accountName
                  : "-"
              }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-w="4" vs-xs="12">
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Request sent</label>
            <span class="font-bold">
              {{ moment(paymentRequestDetail.createdAt) }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Status</label>
            <span class="font-bold">
              {{ paymentRequestDetail.paymentStatus }}
            </span>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card class="mt-10">
      <h4 class="font-bold">Transaction linked to this payment request</h4>
      <vs-table
        v-if="paymentRequestDetail"
        :data="paymentRequestDetail.transactions"
        :max-items="10"
      >
        <template slot="thead">
          <vs-th>
            Date
          </vs-th>

          <vs-th>
            Transaction reference
          </vs-th>
          <vs-th>
            Amount
          </vs-th>

          <vs-th>
            Status
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.createdAt">
              {{ moment(tr.createdAt) }}
            </vs-td>
            <vs-td :data="tr.transactionID">
              {{ tr.transactionID }}
            </vs-td>
            <vs-td :data="tr.amount">
              {{ moneyFormat(tr.amount) }}
            </vs-td>
            <vs-td :data="tr.payeeName">
              {{ paymentRequestDetail.paymentStatus }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      paymentTransactions: [],
      paymentRequestDetail: ""
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["fetchPaymentRequestDetailById"]),
    async fetchDetail(id) {
      await this.fetchPaymentRequestDetailById(id).then(result => {
        this.paymentRequestDetail = result.data.data;
      });
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  },
  mounted() {
    this.fetchDetail(this.$route.params.id);
  }
};
</script>
